.contact-form {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    text-align: center;
}

.contact-form-title {
    font-size: 2rem;
    text-align: center;
}

.contact-form-description {
    text-align: center;
    width: 80%;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0.75rem;
    font-size: 1.5rem;
    border: #BBB solid 2px;
}

.alert-error {
    background: #B10606;
    color: #ffffff;
    padding: 0.75rem;
    font-size: 1em;
    border-radius: 5px;
}

.alert-positive {
    background: #006156;
    color: #ffffff;
    padding: 0.75rem;
    font-size: 1em;
    border-radius: 5px;
}

.loading-container {
    text-align: center;
}

.loading-icon {
    margin-left: auto;
    margin-right: auto;
}