.results-title {
    text-align: center;
    margin-bottom: 1em;
}

.results-description {
    text-align: center;
}

.legend {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}

.legend-color {
    display: inline-block;
    background-color: #000;
    width: 20px;
    height: 20px;
    margin-right: 1em;
}

.legend-color-c {
    background-color: rgb(210, 76, 88);
}

.legend-color-s {
    background-color: rgb(51, 76, 130);
}

.legend-color-i {
    background-color: #AA82B9;
}

.legend-color-f {
    background-color: #599675;
}

.legend-color-g {
    background-color: #E3D170;
}