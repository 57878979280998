.alert-enter {
    opacity: 1;
    transform: translateX(100vw);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .alert-exit-active {
    opacity: 1;
    transform: translateX(-100vw);
    transition: opacity 300ms, transform 300ms;
  }
  
  .question-actions {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3em;
  }
  
  .question-actions button {
    border: none;
    color: rgb(51, 76, 130);
    font-size: 1em;
  }
  
  .question-actions button:hover:not([disabled]) {
    color: #000;
    cursor: pointer;  
  }

  .question-actions button[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .question-actions button:focus {
    outline: none;
  }
  
  .btn__content {
    display: inline-block;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
  .btn__content:focus {
      outline: none;
  }
  
  .question-actions button:focus> .btn__content  {
    box-shadow: 0 0 2px 2px #51a7e8; /* keyboard-only focus styles */
  }

  .next-button {
      background: transparent;
      margin-left: auto;
  }

  .prev-button {
      background: transparent;
      margin-right: auto;
  }