.survey-question-title {
  margin-top: 1em;
  margin-bottom: 1em;
}

.radio-container {
  position: relative;
  display: table;
  position: relative;
  padding-left: 35px;
  margin-bottom: 1.25em;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: #666666 solid 2px;
  background-color: none;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-button {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-container input:checked ~ .radio-button {
  background-color: #666666;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio-button:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  display: none;
}

/* Show the checkmark when checked */
.radio-container input:checked ~ .radio-button:after {
  display: block;
  border-radius: 50%;
}

/* Style the checkmark/indicator
  .radio-container .radio-button:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid white;
  }

  .answers {
      display: table;
      margin-right: auto;
  } */
